import React from 'react';
import { createBrowserRouter, Outlet } from 'react-router-dom';

import Root from 'routes/root';
import pages from 'pages';
import { AdminContextProvider } from 'contexts/AdminContext';
import { ThemeLayout } from 'layouts/ThemeLayout';
import { DashboardLayout } from 'layouts/DashboardLayout';
import { ErrorFallback } from 'shared/components/ErrorBoundary/ErrorBoundary';
import { AuthGuard } from 'guards/AuthGuard';
import { DashboardGuard } from 'guards/DashboardGuard';
import { AdminGuard } from 'guards/AdminGuard';
import { Account } from 'modules/account/screens/Account';
import { Status } from 'modules/status/screens/Status';
import { Support } from 'modules/support/screens/Support';
import ExpiringSessionModal from 'legacy/components/ExpiringSessionModal';
import { Dashboard } from './dashboard';
import { Admin } from './admin/admin';
import { Auth } from './auth';

export const router = createBrowserRouter([
  {
    path: '/',
    element: <Root />,
    errorElement: <ErrorFallback />,
    children: [
      {
        element: <AuthGuard needsSession redirectPath={pages.LOGIN} />,
        children: [
          {
            element: (
              <AdminGuard isAdminDashboard={false} redirectPath={pages.ADMIN}>
                <DashboardGuard />
              </AdminGuard>
            ),
            children: Dashboard,
          },

          {
            element: (
              <ThemeLayout>
                <AdminGuard isAdminDashboard redirectPath={pages.DASHBOARD}>
                  <AdminContextProvider>
                    <Outlet />
                  </AdminContextProvider>
                </AdminGuard>
              </ThemeLayout>
            ),
            children: Admin,
          },
          {
            element: (
              <ThemeLayout>
                <>
                  <ExpiringSessionModal />
                  <DashboardLayout />
                </>
              </ThemeLayout>
            ),
            children: [
              { path: pages.ACCOUNT, element: <Account /> },
              { path: pages.STATUS, element: <Status /> },
              { path: pages.SUPPORT, element: <Support /> },
            ],
          },
        ],
      },
      {
        element: <AuthGuard needsSession={false} redirectPath={`/${pages.DASHBOARD}`} />,
        children: Auth,
      },
    ],
  },
]);

export default router;

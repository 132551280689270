import React from 'react';
import { useTheme } from 'styled-components';

import { IIconProps } from './TIcon';

export function FAQIcon({ color: colorProp }: IIconProps) {
  const theme = useTheme();

  const color = colorProp || theme.colors.chartChartBlueSecondary2100;

  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_2553_1574)">
        <path
          d="M2.28571 1.71429C1.97143 1.71429 1.71429 1.97143 1.71429 2.28571V13.7143C1.71429 14.0286 1.97143 14.2857 2.28571 14.2857H13.7143C14.0286 14.2857 14.2857 14.0286 14.2857 13.7143V2.28571C14.2857 1.97143 14.0286 1.71429 13.7143 1.71429H2.28571ZM0 2.28571C0 1.025 1.025 0 2.28571 0H13.7143C14.975 0 16 1.025 16 2.28571V13.7143C16 14.975 14.975 16 13.7143 16H2.28571C1.025 16 0 14.975 0 13.7143V2.28571ZM4.92143 4.76071C5.20357 3.96429 5.96071 3.42857 6.80714 3.42857H8.88929C10.1357 3.42857 11.1429 4.43929 11.1429 5.68214C11.1429 6.48929 10.7107 7.23571 10.0107 7.63929L8.85714 8.3C8.85 8.76429 8.46786 9.14286 8 9.14286C7.525 9.14286 7.14286 8.76071 7.14286 8.28572V7.80357C7.14286 7.49643 7.30714 7.21429 7.575 7.06071L9.15714 6.15357C9.325 6.05714 9.42857 5.87857 9.42857 5.68571C9.42857 5.38571 9.18571 5.14643 8.88929 5.14643H6.80714C6.68571 5.14643 6.57857 5.22143 6.53929 5.33571L6.525 5.37857C6.36786 5.825 5.875 6.05714 5.43214 5.9C4.98929 5.74286 4.75357 5.25 4.91071 4.80714L4.925 4.76429L4.92143 4.76071ZM6.85714 11.4286C6.85714 11.1255 6.97755 10.8348 7.19188 10.6205C7.40621 10.4061 7.6969 10.2857 8 10.2857C8.30311 10.2857 8.59379 10.4061 8.80812 10.6205C9.02245 10.8348 9.14286 11.1255 9.14286 11.4286C9.14286 11.7317 9.02245 12.0224 8.80812 12.2367C8.59379 12.451 8.30311 12.5714 8 12.5714C7.6969 12.5714 7.40621 12.451 7.19188 12.2367C6.97755 12.0224 6.85714 11.7317 6.85714 11.4286Z"
          fill={color}
        />
      </g>
      <defs>
        <clipPath id="clip0_2553_1574">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}

export default FAQIcon;

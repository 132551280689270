import React, { useState } from 'react';
import DataDogLogs from 'shared/logging/DataDogLogs';
import { useTheme } from 'styled-components';
import { LINKS } from 'shared/links';
import { useTranslation } from 'react-i18next';
import usePageName from 'hooks/usePageName';
import {
  ButtonsWrapper,
  FooterContent,
  FooterWrapper,
  MenuWrapper,
  ScreenCastWrapper,
  ScreenShareWrapper,
  StoreButton,
  StoreButtonLarge,
} from './Footer.styles';
import { AppStoreIcon, AppStoreIconLarge, GooglePlayIcon, GooglePlayIconLarge } from '../icons';
import { ScreenCastIcon } from '../icons/ScreenCastIcon';
import { SurflyErrorModal } from '../SurflyErrorModal/SurflyErrorModal';

export function Footer() {
  const theme = useTheme();
  const { t } = useTranslation();

  const { currentPage } = usePageName();
  const [openModal, setOpenModal] = useState(false);
  const handleClick = () => {
    if (window.Surfly && !window.Surfly.isInsideSession) {
      window.Surfly.session().startLeader();
    } else {
      DataDogLogs.error('Footer', {
        additionalInfo: 'Surfly is not initialized',
      });
      setOpenModal(true);
    }
  };

  const handleCloseModal = () => setOpenModal(false);
  const handleRefresh = () => window.location.reload();

  return (
    <>
      <FooterWrapper>
        <FooterContent>
          <MenuWrapper>
            {LINKS.FOOTER_MENU_OPTIONS.map(({ text, link }) => (
              <li key={String(Math.floor(Math.random() * 10_000))}>
                <a href={link} target="_blank" rel="noreferrer">
                  {text}
                </a>
              </li>
            ))}
          </MenuWrapper>
          {currentPage === 'SUPPORT' ? (
            <ScreenCastWrapper onClick={handleClick}>
              <ScreenCastIcon color={theme.colors.buttonButtonPrimarySecondary2100} />
              <ScreenShareWrapper> {t('share')} </ScreenShareWrapper>
            </ScreenCastWrapper>
          ) : (
            <ButtonsWrapper>
              <StoreButton href={LINKS.MOBILE_APP_STOREFRONTS.apple} target="_blank">
                <AppStoreIcon color={theme.colors.iconIconSecondaryGrey90} />
              </StoreButton>
              <StoreButton href={LINKS.MOBILE_APP_STOREFRONTS.google} target="_blank">
                <GooglePlayIcon color={theme.colors.iconIconSecondaryGrey90} />
              </StoreButton>
              <StoreButtonLarge href={LINKS.MOBILE_APP_STOREFRONTS.apple} target="_blank">
                <AppStoreIconLarge color={theme.colors.iconIconSecondaryGrey90} />
              </StoreButtonLarge>
              <StoreButtonLarge href={LINKS.MOBILE_APP_STOREFRONTS.google} target="_blank">
                <GooglePlayIconLarge color={theme.colors.iconIconSecondaryGrey90} />
              </StoreButtonLarge>
            </ButtonsWrapper>
          )}
        </FooterContent>
        <sub>{t('copyright', { year: new Date().getFullYear() })}</sub>
      </FooterWrapper>
      <SurflyErrorModal
        openModal={openModal}
        onCloseModal={handleCloseModal}
        onRefresh={handleRefresh}
      />
    </>
  );
}

export default Footer;

import React, { useCallback, useEffect } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useBreakpoints } from 'hooks/useWindowSize';
import Button from 'shared/components/Button/Button';
import { AutopayGraphic } from 'shared/components/icons';
import { Modal } from 'shared/components/Modal/Modal';
import { Text } from 'shared/components/Text/Text';
import { perSessionModalKey } from 'constants/localStorageKeys';
import { AutopayModalContent } from './AutopayModal.styles';

export type AutopayModalProps = {
  isOpen: boolean;
  discountEligible: boolean;
  modalKey?: string;
  onCloseAutopayModal: () => void;
};

export function AutopayModal({
  isOpen,
  discountEligible,
  modalKey,
  onCloseAutopayModal,
}: AutopayModalProps) {
  const { t } = useTranslation();
  const screenSize = useBreakpoints();

  useEffect(() => {
    const perSessionModalsRaw = localStorage.getItem(perSessionModalKey);

    if (perSessionModalsRaw !== null) {
      const modalsObj = JSON.parse(perSessionModalsRaw);
      // if this modal has a defined item we've already shown it and can preemptively close the modal
      if (!modalKey || (modalKey && modalsObj[modalKey])) {
        onCloseAutopayModal();
      } else {
        localStorage.setItem(perSessionModalKey, JSON.stringify({ [modalKey]: true }));
      }
    }
  }, [onCloseAutopayModal, modalKey]);

  const handleCloseAutopayModal = useCallback(() => {
    onCloseAutopayModal();
  }, [onCloseAutopayModal]);

  const handleOpenDocuSign = () => {
    // TODO: waiting on BE endpoint
  };

  return (
    <Modal
      contentLabel={
        discountEligible ? t('autopayModal.setUpAutopay') : t('autopayModal.neverMissABill')
      }
      styleVariant="tertiary"
      isOpen={isOpen}
      subHeader
      isFullWidth={screenSize === 'sm'}
      fitContent
      padding="24px"
      onRequestClose={handleCloseAutopayModal}
    >
      <AutopayModalContent>
        <AutopayGraphic />

        {discountEligible ? (
          <>
            <Text as="h2">{t('autopayModal.setUpAutopay')}</Text>
            <Text as="p">
              <Trans>{t('autopayModal.body1')}</Trans>
            </Text>
            <Text as="p">{t('autopayModal.body2')}</Text>
          </>
        ) : (
          <>
            <Text as="h2">{t('autopayModal.neverMissABill')}</Text>
            <Text as="p">{t('autopayModal.goodbyeToPaperChecks')}</Text>
            <Text as="p">{t('autopayModal.safeSecure')}</Text>
          </>
        )}

        <Button label={t('autopayModal.acknowledge')} onClick={handleOpenDocuSign} />
      </AutopayModalContent>
    </Modal>
  );
}

export default AutopayModal;
